import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Messages from './Messages';
import AddCenter from './AddCenter';
import UserProfile from './UserProfile';
import HelpTree from 'components/HelpTree';
import LayoutPage from 'components/Layout';
import CareFacilities from './CareFacilities';
import LoadingPage from 'components/LoadingPage';
import AttendanceSheets, { CareLocations } from './AttendanceSheets';
import ProtectedRoute from 'components/ProtectedRoute';
import { Home } from '@carbon/icons-react';

let menuOptions = [
  {
    label: 'My Locations',
    icon: Home,
    to: '/provider/facilities',
    match: [
      '/provider/facilities',
      '/provider/facility',
      /^\/provider\/facility\/\d+(.*)/,
      /^\/provider\/application\/\d+/,
    ],
  },
  // {
  //   label: 'Messages',
  //   icon: BiEnvelope,
  //   to: '/provider/messages',
  //   match: ['/provider/messages'],
  // },
].filter(Boolean);

const rootBreadcrumb = { text: 'Provider', to: '/provider' };
const addFacilityRegex = /^\/provider\/facility(.*)$/;
const centerProfileRegex = /^\/provider\/profile\/(.*)$/;
const applicationDashboardRegex = /^\/provider\/application(.*)$/;
const breadcrumbs = [
  ['/provider', [rootBreadcrumb]],
  ['/provider/facilities', [rootBreadcrumb, { text: 'Facilities', to: '/provider/facilities' }]],
  ['/provider/user-profile', [rootBreadcrumb, { text: 'Profile', to: '/provider/user-profile' }]],
  [
    centerProfileRegex,
    [rootBreadcrumb, { text: 'Profile', to: '/provider/profile' }, { text: (params) => params.id, to: '' }],
  ],
  ['/provider/profile', [rootBreadcrumb, { text: 'Profile', to: '/provider/profile' }]],
  [
    applicationDashboardRegex,
    [rootBreadcrumb, { text: 'Application', to: '/provider/application' }, { text: (params) => params.id, to: '' }],
  ],
  ['/provider/messages', [rootBreadcrumb, { text: 'Messages', to: '/provider/messages' }]],
  [
    addFacilityRegex,
    [rootBreadcrumb, { text: 'Add Facility', to: '/provider/facility' }, { text: (params) => params.id, to: '' }],
  ],
];
export default function Provider(props) {
  return (
    <LayoutPage {...props} menuOptions={menuOptions} breadcrumbs={breadcrumbs}>
      <React.Suspense fallback={<LoadingPage />}>
        <Switch>
          <ProtectedRoute exact path="/provider" roleRequired="Provider" message="Navigated to Provider Landing Page">
            <Redirect to="/provider/facilities" />
          </ProtectedRoute>

          <ProtectedRoute exact path="/provider/help" roleRequired="Provider" message="Navigated to Help Page">
            <HelpTree />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/provider/facilities"
            roleRequired="Provider"
            message="Navigated to Provider Landing Page"
          >
            <CareFacilities />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/provider/messages"
            roleRequired="Provider"
            message="Navigated to Provider Messages Page"
          >
            <Messages />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/provider/attendance-sheets"
            roleRequired="Provider"
            message="Navigated to Provider Attendance Sheets Page"
          >
            <CareLocations />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/provider/:id/attendance-sheets/:childId"
            roleRequired="Provider"
            message="Navigated to Provider Attendance Sheets Page"
          >
            <AttendanceSheets />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/provider/facility/:id/:step/:tab?"
            roleRequired="Provider"
            message="Navigated to Add Facility Page"
          >
            <AddCenter />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/provider/facility/:id"
            roleRequired="Provider"
            message="Navigated to Add Facility Page"
          >
            <AddCenter />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/provider/facility"
            roleRequired="Provider"
            message="Navigated to Add Facility Page"
          >
            <AddCenter />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/provider/user-profile"
            roleRequired="Provider"
            message="Navigated to User Profile Page"
          >
            <UserProfile />
          </ProtectedRoute>

          <Redirect to="/provider" />
          {/* <ProtectedRoute message="Navigated to Parent Dashboard">
            <h3>No Content yet </h3>
          </ProtectedRoute> */}
        </Switch>
      </React.Suspense>
    </LayoutPage>
  );
}
