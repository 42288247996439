import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button, Input, Divider } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Form from 'components/Form';
import { AsyncSelect } from 'components/Select';
import useEntityGroup from 'hooks/useEntityGroup';
import useStartEndDates from 'hooks/useStartEndDates';
import useChangeRequestClassName from 'hooks/useChangeRequestClassName';
import AgeSelectInputNumber from 'components/AgeSelect/AgeSelectInputNumber';
import FormItem from 'components/FormItem';
import { getValue, formatDate, renderAgeRange, getAgeInWeeks } from 'utils';
import dayjs from 'dayjs';
import SwitchField from 'components/Switch/SwitchField';
import Card from 'components/Card/Card';
import Space, { Spacer } from 'components/Space/Space';
import { Typography } from 'antd';

const { Title } = Typography;

export default function Accreditation({ disabled, value: items, onChange, center, form }) {
  const keys = ['id', 'endDate', 'startDate', 'minAge', 'maxAge', ['type', 'id']];
  const { providerAppsAgeGroupsEnabled } = useFlags();

  const isAccredited = Form.useWatch(['application', 'accredited'], form);

  const rowClassName = useChangeRequestClassName({ keys, snapshotKey: ['accreditations'] });
  const getAllColumns = useCallback(
    ({ addItem, deleteItem, setItem, setVisible }) => {
      const age_groups_col = {
        title: <span className="action-header">Age Group</span>,
        headerText: 'Age Group',
        width: 180,
        key: 'ageRange',
        render: ({ minAge, maxAge }) => (minAge && maxAge ? renderAgeRange({ minAge, maxAge }) : 'N/A'),
      };

      const allColumns = [
        {
          title: <span className="action-header">Accreditation Type</span>,
          headerText: 'Accreditation Type',
          dataIndex: ['type', 'title'],
          key: 'type',
          width: 300,
          render: (_, record) => {
            if (record && record.type) {
              return record.type.description
                ? `${record.type.description}${record.type.title ? ` (${record.type.title})` : ''}`
                : record.type.title;
            }

            return '–';
          },
        },
        {
          title: <span className="action-header">Accreditation Number</span>,
          headerText: 'Accreditation Number',
          dataIndex: 'accreditationNumber',
          key: 'accreditationNumber',
          render: (accreditationNumber) => {
            return accreditationNumber ? accreditationNumber : 'N/A';
          },
        },
        {
          title: <span className="action-header">Start Date</span>,
          headerText: 'Start Date',
          dataIndex: 'startDate',
          key: 'startDate',
          render: (date) => formatDate(date),
          width: 220,
        },
        {
          title: <span className="action-header">End Date</span>,
          headerText: 'End Date',
          dataIndex: 'endDate',
          key: 'endDate',
          render: (date) => formatDate(date),
          width: 220,
        },
      ];
      if (providerAppsAgeGroupsEnabled) {
        allColumns.push(age_groups_col);
      }
      return allColumns;
    },
    [providerAppsAgeGroupsEnabled],
  );

  const getFormFields = useCallback(
    ({ values, setVisible, item, form }) => {
      const getSnapshotValue = (snapshot, name) => {
        return getValue(
          snapshot.accreditations.find((v) => v.id === item?.id),
          name,
        );
      };

      return (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div>
              <FormItem
                name={['type']}
                label="Accreditation Type"
                rules={[{ required: true, message: 'Accreditation Type is required.' }]}
                getSnapshotValue={getSnapshotValue}
                isSame={(prev, curr) => prev?.id === curr?.id}
                data-testid="accreditationTypeSelect"
                id="type"
              >
                <AsyncSelect
                  placeholder="Accreditation Type"
                  ariaLabel="Accreditation Type"
                  optionsApiUrl={`/accreditation-types?typeOfCare=${center?.type?.id}`}
                  getOptionLabel={(option) =>
                    option.description
                      ? `${option.description}${option.title ? ` (${option.title})` : ''}`
                      : option.title
                  }
                />
              </FormItem>
            </div>

            <div>
              {!values?.type?.hasSelfStudy && (
                <FormItem name="accreditationNumber" label="Accreditation Number" getSnapshotValue={getSnapshotValue}>
                  <Input placeholder="Accreditation Number" data-testid="accreditationNumber" />
                </FormItem>
              )}
            </div>

            <StartEndDates form={form} values={values} getSnapshotValue={getSnapshotValue} />
          </div>

          {providerAppsAgeGroupsEnabled && values?.type?.ageRangeRequired === true && (
            <div className="age-section shadow-lg white-box p-6">
              <h3 className="section-title">Ages</h3>

              <Row gutter={[20, 0]} className="form-row">
                <Col xs={24} sm={12} md={12}>
                  <FormItem
                    name={['minAge']}
                    label="Youngest Age"
                    dependencies={['minAge']}
                    rules={[{ required: true, message: 'Youngest Age is required.' }]}
                    getSnapshotValue={getSnapshotValue}
                  >
                    <AgeSelectInputNumber id="accreditation-minAge" />
                  </FormItem>
                </Col>

                <Col xs={24} sm={12} md={12}>
                  <FormItem
                    name={['maxAge']}
                    label="Oldest Age"
                    dependencies={['maxAge']}
                    rules={[
                      { required: true, message: 'Oldest Age is required.' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const minAge = getFieldValue(['minAge']) || {};
                          const maxAge = value || {};
                          if (value === undefined || getAgeInWeeks(minAge) < getAgeInWeeks(maxAge)) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Oldest Age must be greater than Youngest Age Served.');
                        },
                      }),
                    ]}
                    getSnapshotValue={getSnapshotValue}
                  >
                    <AgeSelectInputNumber id="accreditation-maxAge" />
                  </FormItem>
                </Col>
              </Row>
            </div>
          )}

          <Divider />

          <Space>
            <Spacer />

            <Button
              onClick={() => setVisible(false)}
              data-testid="cancel-accreditation-btn"
              style={{ borderRadius: '2px' }}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              data-testid="save-accreditation-btn"
              style={{ borderRadius: '2px' }}
            >
              Save
            </Button>
          </Space>
        </>
      );
    },
    [center?.type?.id, providerAppsAgeGroupsEnabled],
  );

  const { section } = useEntityGroup({
    items: (items || []).sort((a, b) => (!b.endDate ? -1 : dayjs(a.endDate) - dayjs(b.endDate))),
    onChange,
    entity: center,
    getAllColumns,
    getFormFields,
    modalWidth: 800,
    entityTitle: 'accreditation',
    tableProps: {
      rowClassName,
    },
    dateFields: [['startDate'], ['endDate']],
    entityName: 'accreditation',
    readOnly: disabled,
  });

  return (
    <Card
      collapsible
      title={
        <Title level={3} className="!mb-0">
          Accreditation
        </Title>
      }
      className="mb-4"
      data-testid="accreditation"
    >
      <p>
        Are you currently nationally accredited, in the process of renewal or self study with an accrediting agency, or
        do you have eligible degrees, transcripts, or awards?
      </p>

      <FormItem
        data-testid="accreditation-status"
        name={['application', 'accredited']}
        valuePropName="checked"
        className="m-0"
      >
        <SwitchField name={['application', 'accredited']} />
      </FormItem>

      {isAccredited && (
        <>
          <p className="instructions mb-5 mt-4">
            My facility is accredited through the following "If the following statement is true, please provide your
            facility's, Department of Defense, recognized National Accreditation certificate information or indicate if
            your facility is participating in a self-study. If you are a Family Child Care Home and not participating in
            a National Accreditation, but have applicable (as stated in the MCCYN criteria,{' '}
            <a
              href="https://www.childcareaware.org/fee-assistancerespite/feeassistancerespiteproviders/feeassistance/#"
              target="_blank"
              rel="noreferrer"
            >
              Why Accredited Care Is Important?
            </a>
            ) Degrees, Transcripts or Awards, then please provide the information in this section. Upload the relevant
            Certificates, Degrees, Transcripts, Awards, or self-study approval in the document section below. If you
            have yet to receive your documentation, you may upload a copy of most recent contact (ie. email) confirming
            your status with the accrediting body with a potential timeframe for an update. of Defense recognized
            accreditations:
          </p>

          <Form.Item
            name="accreditation"
            dependencies={[['accreditation']]}
            rules={[
              (form) => ({
                validator: (rule, value) => {
                  const accreditations = form.getFieldValue(['accreditations']);
                  if (accreditations?.length === 0 && isAccredited) {
                    return Promise.reject('At least one accreditation is required.');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            {section}
          </Form.Item>
        </>
      )}
    </Card>
  );
}

Accreditation.propTypes = {
  center: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.array,
};

const StartEndDates = ({ form, values, getSnapshotValue }) => {
  const [startDate, endDate] = useStartEndDates({
    form,
    values,
    startDateFieldProps: {
      label: 'Start Date',
      rules: [{ required: true, message: 'Accreditation Start Date is required.' }],
      getSnapshotValue,
    },
    endDateFieldProps: {
      label: 'Expiration Date',
      rules: [
        {
          required: !values?.type?.doesNotExpire,
          message: 'Expiration Date is required.',
        },
      ],
      getSnapshotValue,
    },
    startDatePickerProps: {
      'data-testid': 'startDate_container',
    },
    endDatePickerProps: {
      disabled: values?.type?.doesNotExpire,
      'data-testid': 'endDate_container',
    },
  });
  return (
    <>
      <div>{startDate}</div>
      <div>{endDate}</div>
    </>
  );
};
